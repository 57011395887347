function menuParser(snippetData, theme, sessionStorageData = []) {
    if (!snippetData || snippetData.error) {
        return [];
    }

    const lines = String(snippetData.text).split('\n');
    const menuStructured = [];
    let currentLevelGroup = null;

    lines.forEach((line) => {
        const parts = line
            .replace(/["\r]/g, '')
            .split('\t')
            .map((part) => part.trim());

        const [level, linkText, linkSlug, linkColorLight, linkColorDark, linkRel, isExternal] = parts;
        const linkColor = theme === 'light' ? linkColorLight : linkColorDark;

        // Check if the item exists in sessionStorage
        const storedItem = sessionStorageData.find(([text]) => text === linkText);
        const subsOpen = storedItem ? storedItem[1] : false;

        const item = { level, linkText, linkSlug, linkColor, linkRel, isExternal: Number(isExternal) };

        if (level === '1') {
            currentLevelGroup = [{ ...item, subsOpen: subsOpen }, []];
            menuStructured.push(currentLevelGroup);
        } else if (level === '2' && currentLevelGroup) {
            currentLevelGroup[1].push(item);
        }
    });
    return menuStructured;
}

function notificationBarParser(snippetData): any {
    const menuDefault = [];
    if (!snippetData || snippetData.error) {
        return menuDefault;
    }
    const text = String(snippetData.content);

    return text;
}

export { notificationBarParser, menuParser };
