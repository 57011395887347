import '../styles/css/globals.css';
import '../styles/scss/common-scss/_variables.scss';
import '../styles/scss/snippets/_snippets.scss';
import '../styles/scss/common-scss/sharedComponents/_sharedComponents.scss';

import { IncomingMessage } from 'http';
import { useRouter } from 'next/router';

interface IncomingMessageWithCookies extends IncomingMessage {
    cookies: { [key: string]: string };
}
import { notificationBarParser } from '@data/menuParser';
import App, { AppContext, AppInitialProps, AppProps } from 'next/app';
import Head from 'next/head';

import MyProvider from '../context/provider';

type AppOwnProps = { menu: any; notificationBarData: any; notificationBarState: boolean; loadHotjar: any };

export default function MyApp({
    Component,
    pageProps,
    menu,
    notificationBarData,
    notificationBarState,
    loadHotjar
}: AppProps & AppOwnProps) {
    const router = useRouter();
    // Function to check if Hotjar should load based on the rules
    const shouldLoadHotjar = () => {
        if (!loadHotjar) return false;
        // Parse the rules from the API response
        const rules = loadHotjar?.text.split('\r\n') || [];

        // If 'true' is the only rule, load on all pages
        if (rules.length === 1 && rules[0] === 'true') return true;

        // If 'true' exists with other paths, check the paths
        if (rules.includes('true') && rules.length > 1) {
            return rules.some((rule) => {
                if (rule === 'true') return false; // Skip the 'true' entry

                // Handle wildcard paths
                if (rule.endsWith('*')) {
                    const basePath = rule.slice(0, -1); // Remove the *
                    return router.pathname.startsWith(basePath);
                }

                // Exact path matching
                return router.pathname === rule;
            });
        }

        return false;
    };

    return (
        <>
            <Head>
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="apple-mobile-web-app-capable" content="yes" />
                <meta name="mobile-web-app-capable" content="yes" />
                {/* Hotjar Tracking Code for RabbitsReviews */}
                {shouldLoadHotjar() && (
                    <script
                        dangerouslySetInnerHTML={{
                            __html: `
                                    (function(h,o,t,j,a,r){
                                        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                                        h._hjSettings={hjid:3278089,hjsv:6};
                                        a=o.getElementsByTagName('head')[0];
                                        r=o.createElement('script');r.async=1;
                                        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                                        a.appendChild(r);
                                    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
                                `
                        }}
                    />
                )}
                <link rel="icon" type="image/png" href="https://assets.rabbitsreviews.com/favicon.ico" />
                <link rel="preconnect" href="https://www.googletagmanager.com" crossOrigin="anonymous" />
                <link rel="preconnect" href="https://api.rabbits.webcam" crossOrigin="anonymous" />
                <link rel="preconnect" href="https://www.google-analytics.com" crossOrigin="anonymous" />
                <link rel="preconnect" href="https://cdn.izooto.com" crossOrigin="anonymous" />
                <link rel="preconnect" href="https://assets.rabbitsreviews.com" crossOrigin="anonymous" />
                <link rel="dns-prefetch" href="https://www.googletagmanager.com" crossOrigin="" />
                <link rel="dns-prefetch" href="https://api.rabbits.webcam" crossOrigin="" />
                <link rel="dns-prefetch" href="https://www.google-analytics.com" crossOrigin="" />
                <link rel="dns-prefetch" href="https://cdn.izooto.com" crossOrigin="" />
                <link rel="dns-prefetch" href="https://assets.rabbitsreviews.com" crossOrigin="" />
                <link rel="preconnect" href="https://dev.visualwebsiteoptimizer.com" />

                {/* This Algolia link is to carry out the handshake immediately
                after loading the page but before any user interaction. See https://www.algolia.com/doc/guides/building-search-ui/going-further/improve-performance/react-hooks/  */}
                <link crossOrigin="anonymous" href="https://2OH78GUKE0-dsn.algolia.net" rel="preconnect" />
            </Head>
            <MyProvider menuData={menu} notificationBarData={notificationBarData} notificationBarState={notificationBarState}>
                <Component {...pageProps} />
            </MyProvider>
        </>
    );
}

MyApp.getInitialProps = async (context: AppContext): Promise<AppOwnProps & AppInitialProps> => {
    const ctx = await App.getInitialProps(context);
    const [menuData, notificationBarData, loadHotjar] = await Promise.all([
        fetch(process.env.NEXT_PUBLIC_API_DOMAIN + '/v1/snippet/main-menu-items').then((res) => res.json()),
        fetch(process.env.NEXT_PUBLIC_API_DOMAIN + '/v1/snippets/notification-bar').then((res) => res.json()),
        fetch(`${process.env.NEXT_PUBLIC_API_DOMAIN}/v1/snippet/load-hotjar`).then((res) => res.json())
    ]);
    const notificationBar = notificationBarParser(notificationBarData);
    const reqWithCookies = context.ctx.req as IncomingMessageWithCookies;
    const notificationBarDefault =
        reqWithCookies?.cookies?.notificationconsent_status === 'true' ||
        reqWithCookies?.cookies?.notificationconsent_status === undefined;

    return {
        ...ctx,
        menu: menuData,
        notificationBarData: notificationBar,
        notificationBarState: notificationBarDefault,
        loadHotjar: loadHotjar
    };
};
